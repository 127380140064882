import React from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'

import ProgressBar from '../../ProgressBar'
import './Slide.css'

const Slide = ({ datas: {featured_media, id, path, title}}) => (
  <div className="slide">
    <div className="holder">
      <div className="container">
        <div className="slide__caption">
          <h2 aria-label={title} className="slide__title" data-splitting>{title}</h2>
        </div>
      </div>
      {featured_media && featured_media.localFile && (
        <picture>
          <source data-srcset={featured_media.localFile.childImageSharp.sizes.src} />
          <img src={featured_media.localFile.childImageSharp.sizes.tracedSVG} className="slide__img lazyload" alt={featured_media.localFile.childImageSharp.sizes.originalName} />
        </picture>
      )}
      <TransitionLink
        className="slide__link"
        data-splitting
        exit={{
          length: .9,
        }}
        entry={{
          delay: .9,
        }}
        state={{
          index: id,
        }}
        title="Voir le détail du projet"
        to={path}
      >
        Découvrir le projet
      </TransitionLink>
   </div>
   <ProgressBar />
  </div>
)

Slide.propTypes = {
  datas: PropTypes.object.isRequired,
}

export default Slide
