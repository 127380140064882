import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import Slide from './Slide'

import './Carousel.css'

const Carousel = forwardRef(({ data, index }, ref) => {
  const datas = data.allWordpressWpProject.edges

  return (
    <div className="glide" ref={ref}>
      <div className="glide__track" data-glide-el="track">
        <div className="glide__slides">
          {datas
            .sort((x, y) => x.node.id === index ? -1 : y.node.id === index ? 1 : 0)
            .map(({node}, i) => (
              <div key={node.id} className="glide__slide">
                <Slide datas={node} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
})

Carousel.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.string,
}

export default Carousel
