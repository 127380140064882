import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { TransitionState } from 'gatsby-plugin-transition-link'
import Glide from '@glidejs/glide'

import Carousel from '../components/Carousel'
import Layout from '../components/layout'
import SEO from '../components/seo'

class IndexPage extends Component {
  constructor(props) {
    super()

    this.state = {
      index: props.location.state && typeof props.location.state.index !== 'undefined' ? props.location.state.index.toString() : '',
    }

    this.slidePause = this.slidePause.bind(this)
    this.slidePlay = this.slidePlay.bind(this)

    this.ref = React.createRef()
  }

  componentDidMount() {
    this.glide = new Glide(this.ref.current, {
      animationDuration: 0,
      autoplay: 9000,
      gap: 0,
      hoverpause: false,
      touchRatio: 0,
    })

    this.glide.mount()

    this.glide.on('run.before', this.glideRunBefore.bind(this))

    this.glide.on('run.after', this.glideRunAfter.bind(this))
  }

  componentWillUnmount() {
    this.glide.destroy()
  }

  /**
   * Method just before slide will change
   */
  glideRunBefore() {
    const index = this.glide.index

    window.setTimeout(() => {
      this.ref.current.querySelectorAll('.glide__slide')[index].classList.remove('glide__slide--visible')
    }, 1000)
  }

  /**
   * Method after slide has changed
   */
  glideRunAfter() {
    this.ref.current.querySelectorAll('.glide__slide')[this.glide.index].classList.add('glide__slide--visible')
  }

  /**
   * Pause the slider
   */
  slidePause() {
    if (typeof this.glide !== 'undefined') {
      this.glide.pause()
    }
  }

  /**
   * Play the slider
   */
  slidePlay() {
    if (typeof this.glide !== 'undefined') {
      this.glide.play()
    }
  }

  render() {
    const { data } = this.props
    const { index } = this.state

    return (
      <TransitionState>
        {({mount, transitionStatus}) => {
          if (transitionStatus === `exiting`) {
            this.slidePause()
          }

          return (
            <Layout
              className="home"
              handleSlidePause={this.slidePause}
              handleSlidePlay={this.slidePlay}
            >
              <SEO
                description={data.wordpressSiteMetadata.description}
                title={`${data.wordpressSiteMetadata.name} — ${data.wordpressSiteMetadata.description}`}
              />
              <Carousel data={data} index={index} ref={this.ref} />
            </Layout>
          )
        }}
      </TransitionState>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query {
    allWordpressWpProject(sort: { fields: [date] }) {
      edges {
        node {
          featured_media {
            localFile {
              childImageSharp {
                sizes(maxWidth: 1260) {
                  originalName
                  src
                  tracedSVG
                }
              }
            }
          }
          id
          path
          title
        }
      }
    }
    wordpressSiteMetadata {
      name
      description
    }
  }`

export default IndexPage
